<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{formFields.id ? `Edit ${operationTitle}` : `Add ${operationTitle}`}}
          </template>
          <template v-slot:toolbar>
            <b-button class="mr-2 mt-3" @click="handleSubmitOperation"
                      size="sm"
                      variant="primary"
                      :disabled="global.pendingRequests > 0"
            >
              <i v-show="global.pendingRequests > 0"
                 class="fa fa-spin fa-spinner"></i>
              <i class="fa fa-save fa-sm"></i>
              Save
            </b-button>
            <b-button variant="danger" size="sm" class="mt-3"
                      @click="handleOperationClose()">
              <i class="fa fa-arrow-left fa-sm"></i> Cancel
            </b-button>
          </template>
          <template v-slot:body>
            <v-card>
              <div class="guest-rooms-operation">
                <b-container fluid>
                  <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                    <b-row>
                      <b-col class="p-0" cols="12" md="12" lg="12" sm="12">
                        <b-row>
                          <b-col sm="6">
                            <b-form-group
                                label="Name Of Room *"
                                label-for="title"
                                :invalid-feedback="formErrors.first('title')"
                            >
                              <b-form-input
                                  id="title"
                                  v-model="formFields.title"
                                  type="text"
                                  :state="((formErrors.has('title') ? false : null))"
                                  @focus="$event.target.select()"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="6">
                            <b-form-group
                                label="Room No. *"
                                label-for="room_no"
                                :invalid-feedback="formErrors.first('room_no')"
                            >
                              <b-form-input
                                  id="room_no" v-numericOnly.keyup
                                  v-model="formFields.room_no"
                                  type="number"
                                  :state="((formErrors.has('room_no') ? false : null))"
                                  @focus="$event.target.select()"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row><!--/b-row-->
                        <b-row>
                          <b-col sm="6">
                            <b-form-group
                                label="Floor No."
                                label-for="floor_no"
                                :invalid-feedback="formErrors.first('floor_no')"
                            >
                              <b-form-input
                                  id="floor_no" v-numericOnly.keyup
                                  v-model="formFields.floor_no"
                                  type="number"
                                  :state="((formErrors.has('floor_no') ? false : null))"
                                  @focus="$event.target.select()"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="6">
                            <b-form-group
                                label="Place Name"
                                label-for="place_name"
                                :invalid-feedback="formErrors.first('place_name')"
                            >
                              <b-form-input
                                  id="place_name"
                                  v-model="formFields.place_name"
                                  type="text"
                                  :state="((formErrors.has('place_name') ? false : null))"
                                  @focus="$event.target.select()"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row>
                        <hr />
                        <b-row class="operation-footer">
                          <b-col sm="12">
                            <b-button
                                size="sm"
                                type="submit"
                                variant="primary"
                                :disabled="global.pendingRequests > 0"
                            >
                              <i v-show="global.pendingRequests > 0"
                                 class="fa fa-spin fa-spinner"></i>
                              <i class="fa fa-save fa-sm"></i>
                              Save
                            </b-button>
                            <b-button variant="danger" class="ml-3"
                                      size="sm"
                                      @click="handleOperationClose()">
                              <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-col><!--/b-col-->
                    </b-row><!--/b-row-->
                  </form><!--/form-->
                </b-container><!--/b-container-->
              </div><!--/.guest-rooms-operation-->
            </v-card>
          </template>
        </KTCard>
      </div>
    </div>
  </div>
</template>

<script>
  import KTCard from "@/view/content/Card.vue";
  import moduleMixin from "./module.mixin";
  import { mapGetters, mapState } from "vuex";

  export default {
    mixins: [moduleMixin],
    components: {
      KTCard,
    },
    mounted() {
      if ( this.$route.params && this.$route.params.id && this.$route.params.type ) {
        this.handleEditOperation(this.$route.params.id);
      }
    },
    computed: {
      ...mapState([
        'global',
      ]),
      ...mapGetters({ getActiveProperty: 'getActiveProperty' }),
    },
  };
</script>
